import "@splidejs/splide/css";
import Splide from "@splidejs/splide";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { CountUp } from "countup.js";

import Flickity from "flickity";
import "flickity/css/flickity.css";

gsap.registerPlugin(ScrollTrigger);

// Hero Slider
const splide = new Splide(".splide", {
  type: "fade",
  rewind: true,
  pagination: false,
  autoplay: true,
  perPage: 1,
  arrows: false,
  interval: 4000,
}).mount();

const setActiveAnimation = (index) => {
  const elementSplideSlide = document.querySelectorAll(".splide__slide");
  gsap
    .timeline()
    .to("#" + elementSplideSlide[index].id, { scale: 1.1, duration: 2 })
    .to("#" + elementSplideSlide[index].id, { scale: 1, duration: 2 })
    .to("#" + elementSplideSlide[index].id, { scale: 1, duration: 0 });
  const elementProgressSlide = document.querySelectorAll(
    ".slide-progress--line",
  );

  elementProgressSlide.forEach((element) => {
    const proggresSlideIndex = element.getAttribute("index");
    const pregresSlideId = elementProgressSlide[proggresSlideIndex].id;
    if (index == parseInt(proggresSlideIndex)) {
      gsap.to("#" + pregresSlideId, { opacity: 1, duration: 1 });
    } else {
      gsap.to("#" + pregresSlideId, { opacity: 0, duration: 1 });
    }
  });

  document.querySelector(".slide-progress--active-number").innerHTML = `0${
    index + 1
  }`;
  document.querySelector(
    ".slide-progress--text",
  ).innerHTML = `${slider[index].alt}`;
};

setActiveAnimation(0);
splide.on("active", function (e) {
  setActiveAnimation(e.index);
});
/////////////////////////////////////////////////////////////////////////

// Hero Heading Animation
gsap.from(".row span", {
  duration: 1.2,
  delay: 0,
  opacity: 0,
  stagger: 0.02,
  y: 100,
  ease: "power1.out",
});
//////////////////////////////////////////////////////////////////////////

// Image Preview hover

const items = document.querySelectorAll(".frontpage-project--wrap");

items.forEach((el) => {
  const image = el.querySelector(".frontpage-project--preview-img");

  el.addEventListener("mouseenter", (e) => {
    gsap.to(image, { autoAlpha: 1, duration: 0 });
  });

  el.addEventListener("mouseleave", (e) => {
    gsap.to(image, { autoAlpha: 0, duration: 0 });
  });

  el.addEventListener("mousemove", (e) => {
    if (e.target.classList.contains("frontpage-project--wrap")) {
      gsap.to(image, { autoAlpha: 1, duration: 0 });
      gsap.set(image, {
        force3D: true,
        x: e.offsetX - 100,
        y: e.offsetY - 100,
      });
    } else {
      gsap.to(image, { autoAlpha: 0, duration: 0 });
    }
  });
});

const featuredProjectsBtn = document.querySelectorAll(".featured-project-btn");

gsap.to(`.frontpage-project--featured-containter`, {
  autoAlpha: 0,
  height: 0,
  ease: "power4.out",
  duration: 1,
});

featuredProjectsBtn.forEach((featuredProject) => {
  featuredProject.addEventListener("click", (e) => {
    e.currentTarget.classList.toggle("active");
    if (!e.currentTarget.classList.contains("active")) {
      gsap.to(
        `.frontpage-project--wrap-${e.currentTarget.getAttribute(
          "index",
        )} .frontpage-project--featured-containter`,
        {
          autoAlpha: 0,
          height: 0,
          ease: "power4.out",
          duration: 1,
        },
      );
    } else {
      gsap.to(
        `.frontpage-project--wrap-${e.currentTarget.getAttribute(
          "index",
        )} .frontpage-project--featured-containter`,
        {
          autoAlpha: 1,
          height: "auto",
          ease: "power4.out",
          duration: 1,
        },
      );
    }
  });
});

////////////////////////////////////////////////////////////////////////
// const projectWraps = document.querySelectorAll(".frontpage-project--wrap");
// const projectPreview = document.querySelector(".preview-project");

// const previewProject = (e) => {
//   const x = e.clientX;
//   const y = e.clientY;

//   projectPreview.style.left = `${x}px`;
//   projectPreview.style.top = `${y}px`;
// };

// const mouseoverProject = (e) => {
//   const imgUrl = e.target.getAttribute("image-url");
//   if (imgUrl !== null) {
//     document.src = imgUrl;
//     gsap.to(projectPreview, {
//       opacity: 1,
//       duration: 0.3,
//       stagger: 0.12,
//     });

//     document.addEventListener("mousemove", previewProject);
//   }
// };

// const mouseoutProject = (e) => {
//   const imgUrl = e.currentTarget.getAttribute("image-url");
//   if (imgUrl !== null) {
//     projectPreview.src = imgUrl;
//     gsap.to(projectPreview, {
//       opacity: 0,
//       duration: 0.3,
//       stagger: 0.12,
//     });

//     document.removeEventListener("mousemove", previewProject);
//   }
// };

// projectWraps.forEach((projectWrap) => {
//   projectWrap.addEventListener("mouseover", mouseoverProject, true);
//   projectWrap.addEventListener("mouseout", mouseoutProject, true);
// });

/// animate about us

gsap
  .timeline({
    scrollTrigger: {
      trigger: ".frontpage-about-us-section",
      start: "top 80%",
      end: "bottom 0%",
    },
  })
  .to(".frontpage-aboutus--image-container .revealer", {
    height: 0,
    duration: 2,
  })
  .from(".frontpage-aboutus--animate-text", {
    y: 50,
    opacity: 0,
    duration: 0.5,
    ease: "power1.out",
  })
  .from(".frontpage-aboutus--animate-heading", {
    y: 120,
    duration: 1,
    ease: "power1.out",
  })
  .from(".frontpage-about-us-section .btn-arrow-text", {
    x: 120,
    opacity: 0,
    duration: 0.5,
    ease: "power1.out",
  });

/////////////////////////////

const countUp = () => {
  const counterElements = document.querySelectorAll(".count-up");
  counterElements.forEach((counterElement) => {
    const counterElementId = counterElement.id;
    const counterElementNumber = parseInt(counterElement.innerHTML);
    const number = new CountUp(`${counterElementId}`, counterElementNumber, {
      duration: 3,
    });
    number.start();
  });
};

ScrollTrigger.create({
  trigger: ".frontpage-project-number",
  // markers: true,
  start: "top 100%",
  // endTrigger: ".frontpage-project-number",
  end: "bottom 50%+=100px",
  once: true,
  onToggle: countUp,
});

// gsap.to("#div_block-175-11", { height: 0, duration: 2 });

// gsap.from(".frontpage-aboutus--animate-text", {
//   y: 50,
//   opacity: 0,
//   delay: 2,
//   duration: 1,
//   ease: "power1.out",
// });

// gsap.from(".frontpage-aboutus--animate-heading", {
//   y: 120,
//   delay: 3,
//   duration: 1,
//   ease: "power1.out",
// });

// gsap.from(".frontpage-about-us-section .btn-arrow-text", {
//   x: 120,
//   opacity: 0,
//   delay: 2,
//   duration: 1,
//   ease: "power1.out",
// });

//
//   Variables
//
//////////////////////////////////////////////////////////////////////

// Play with this value to change the speed
let tickerSpeed = 2;

let flickity = null;
let isPaused = false;
const slideshowEl = document.querySelector(".frontpage-our-client--container");

const update = () => {
  if (isPaused) return;
  if (flickity.slides) {
    flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
    flickity.selectedIndex = flickity.dragEndRestingSelect();
    flickity.updateSelectedSlide();
    flickity.settle(flickity.x);
  }
  window.requestAnimationFrame(update);
};

const pause = () => {
  isPaused = true;
};

const play = () => {
  if (isPaused) {
    isPaused = false;
    window.requestAnimationFrame(update);
  }
};

flickity = new Flickity(slideshowEl, {
  autoPlay: false,
  prevNextButtons: false,
  pageDots: false,
  draggable: true,
  wrapAround: true,
  selectedAttraction: 0.015,
  friction: 0.25,
});
flickity.x = 0;

slideshowEl.addEventListener("mouseenter", pause, false);
slideshowEl.addEventListener("focusin", pause, false);
slideshowEl.addEventListener("mouseleave", play, false);
slideshowEl.addEventListener("focusout", play, false);

flickity.on("dragStart", () => {
  isPaused = true;
});

update();

// var flkty = new Flickity(".frontpage-our-client--container", {
//   freeScroll: true,
//   contain: true,
//   // disable previous & next buttons and dots
//   prevNextButtons: false,
//   pageDots: false,
//   imagesLoaded: true,
//   freeScroll: true,
//   wrapAround: true,
// });

document.querySelectorAll(".frontpage-our-client--wrap").forEach((element) => {
  element.style.display = "flex";
  element.style.height = "100%";
  element.style.alignItems = "center";
});
